<template>
  <b-row class="filters-container">
    <b-col cols="4">
      <label for="healthPlan">Convênio</label>
      <HealthPlanFilter :selectHealthPlan="handleSelectedHealthPlans" />
    </b-col>

    <b-col cols="3">
      <label for="invoiceNumber">Nº da Fatura</label>
      <b-form-input
        id="invoiceNumber"
        v-model="filters.invoiceNumber"
        placeholder="Pesquisar"
        debounce="500"
      />
    </b-col>

    <b-col cols="2">
      <label for="situation">Situação</label>
      <Autocomplete
        id="situation"
        v-model="filters.situation"
        :options="tissInvoiceStatusOptions"
        placeholder="Selecionar"
      />
    </b-col>

    <b-col cols="3">
      <div class="label-container">
        <label for="">Período</label>
        <b-form-radio v-model="filters.period.type" value="create">
          Criação
        </b-form-radio>
        <b-form-radio v-model="filters.period.type" value="close">
          Fechamento
        </b-form-radio>
      </div>
      <Periods @select="handlePeriod" />
    </b-col>
  </b-row>
</template>

<script>
import { tissInvoiceStatusOptions } from '@/modules/tiss/manageGuides/utils/statuses'

export default {
  components: {
    Periods: () => import('@/components/General/Periods'),
    Autocomplete: () => import('@/components/Autocomplete'),
    HealthPlanFilter: () => import('./HealthPlanFilter')
  },
  data() {
    return {
      filters: {
        healthPlan: null,
        invoiceNumber: null,
        situation: null,
        period: {
          start: null,
          end: null,
          type: 'create'
        }
      },
      tissInvoiceStatusOptions,
    }
  },
  methods: {
    handlePeriod(period) {
      this.filters.period.start = period.start
      this.filters.period.end = period.end
    },
    handleSelectedHealthPlans(newValue) {
      this.filters.healthPlan = newValue?.value ?? null
    }
  },
  watch: {
    filters: {
      handler(newValue) {
        this.$emit('change', newValue)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-container {
  display: flex;
  flex-direction: row;
  margin: 20px 0;

  .label-container {
    display: flex;
    flex-direction: row;

    label {
      margin-right: 20px;
    }

    div {
      font-size: 15px !important;
      margin-right: 15px;
    }
  }

  label {
    font-weight: 700;
  }
}
</style>
